<template>
  <div class="prentView orderQueryView">
    <div class="topView">
      <div class="backView">
        <div class="tabs" v-if="heading">
          <el-tabs v-model="washWayActiveName" @tab-click="handleWashWayClick">
            <el-tab-pane label="按件收" name="1"></el-tab-pane>
            <el-tab-pane label="团购套餐" name="2"></el-tab-pane>
            <el-tab-pane label="门店套餐" name="3"></el-tab-pane>
          </el-tabs>
        </div>
        <el-page-header
          v-if="isView"
          @back="back"
          title="返回"
          :content="pageHeaderTitle"
        />
        <el-page-header
          v-else
          @back="$router.go(-1)"
          title="返回"
          :content="pageHeaderTitle"
        />
      </div>
    </div>

    <div class="contentView">
      <div
        class="leftView"
        style="padding-right: 500px;"
        v-loading="getLoading"
        element-loading-text="加载中"
      >
        <div class="sellInfoView" v-if="isView">
          <div class="cusView">
            <div class="tView">顾客信息</div>
            <div class="infoView">
              <div class="inputView">
                <label>联系方式</label>
                <input disabled v-model="userInfo.phone" />
              </div>
              <div class="inputView">
                <span class="stars">*</span>
                <label>姓名</label>
                <input v-model="userInfo.realName" />
              </div>
              <div class="inputView">
                <label>性别</label>
                <input :value="'男'" disabled v-if="userInfo.gender == 1" />
                <input :value="'女'" disabled v-if="userInfo.gender == 2" />
              </div>
              <div class="inputView">
                <label>出生日期</label>
                <input :value="userInfo.birthday" disabled />
              </div>
              <div class="inputView" v-if="this.userInfo.memberUser">
                <label>会员折扣</label>
                <span
                style="font-size:12px;transform:scale(0.9)"  
                >{{'全净洗:' +
                      userInfo.memberUser.discount/10+'折'+
                      ',奢护洗:' +
                      userInfo.memberUser.luxuryDiscount/10+'折'+
                      ',保值洗:' +
                      userInfo.memberUser.hedgingDiscount/10+'折'}}</span>
              </div>
              <div class="inputView" v-if="this.userInfo.memberUser">
                <label>洗鞋权益</label>
                <input
                  v-if="userInfo.memberUser.memberUserBenefits"
                  disabled
                  :value="
                    userInfo.memberUser.memberUserBenefits.length > 0
                      ? '是'
                      : '否'
                  "
                />
                <input v-else disabled value="否" />
              </div>
              <div class="inputView">
                <label>最近消费</label>
                <div
                  v-if="this.userInfo.lastMoneyRecord"
                  class="inputView-date"
                >
                  {{ this.userInfo.lastMoneyRecord.createTime | date }}

                  <label class="query-result-main-label inputView-label">
                    {{ this.userInfo.lastMoneyRecord.storeName }}
                  </label>
                </div>
              </div>
              <div class="inputView">
                <label>标签</label>
                <div>
                  <span v-if="tagList.length > 3">
                    <el-tag size="mini">{{ tagList[0] }}</el-tag>
                    <el-tag size="mini">{{ tagList[1] }}</el-tag>
                    <el-tag size="mini">{{ tagList[2] }}</el-tag>
                  </span>
                  <span v-else>
                    <el-tag
                      size="mini"
                      v-for="(item, index) in tagList"
                      :key="index"
                    >
                      {{ item }}
                    </el-tag>
                  </span>
                </div>
                <p @click="more">
                  更多
                  <i class="el-icon-arrow-right"></i>
                </p>
              </div>
            </div>
          </div>
          <div class="accView">
            <div class="tView">账户信息</div>
            <div class="buyInfoView">
              <div class="amtView">
                <div class="inputView" v-if="this.userInfo.memberUser">
                  <label>储值总余额</label>
                  <input
                    placeholder="0"
                    disabled
                    :value="
                      userInfo.memberUser.balance
                        ? userInfo.memberUser.balance
                        : 0 + '元'
                    "
                  />
                </div>
                <div class="inputView" v-else>
                  <label>储值总余额</label>
                  <input placeholder="0元" disabled />
                </div>

                <div class="inputView" v-if="userInfo.memberUser">
                  <label>通享卡余额</label>
                  <input
                    :value="
                      userInfo.memberUser.headquartersBalance
                        ? userInfo.memberUser.headquartersBalance
                        : 0 + '元'
                    "
                    disabled
                  />
                </div>
                <div class="inputView" v-else>
                  <label>通享卡余额</label>
                  <input placeholder="0元" disabled />
                </div>

                <div class="inputView" v-if="userInfo.memberUser">
                  <label>本店历史余额</label>
                  <input
                    :value="
                      userInfo.memberUser.currStoreBalance
                        ? userInfo.memberUser.currStoreBalance
                        : 0 + '元'
                    "
                    disabled
                  />
                </div>
                <div class="inputView" v-else>
                  <label>本店历史余额</label>
                  <input placeholder="0元" disabled />
                </div>
                
                <div class="inputView">
                  <label>会员到期时间</label>
                  <input
                    v-if="userInfo.memberUser && userInfo.memberUser.expireDate"
                    disabled
                    :value="userInfo.memberUser.expireDate | date1"
                  />
                </div>
                <div class="inputView">
                  <label>会员剩余时间</label>
                  <input
                    v-if="userInfo.memberUser"
                    disabled
                    :value="userInfo.memberUser.expireDay"
                  />
                </div>
                <div class="inputView">
                  <label>待取衣物</label>
                  <input
                    placeholder="0"
                    disabled
                    :value="userInfo.waitTakeClothes"
                  />
                </div>
                <div class="inputView">
                  <label>消费次数</label>
                  <input
                    placeholder="0"
                    disabled
                    :value="userInfo.consumeTimes"
                  />
                </div>
                <div class="inputView" v-if="userInfo.memberUser">
                  <label>权益优惠</label>
                  <input
                    placeholder="0"
                    disabled
                    :value="userInfo.memberUser.totalDiscountAmount + '元'"
                  />
                </div>
                <div class="inputView" v-else>
                  <label>权益优惠</label>
                  <input placeholder="0元" disabled />
                </div>
              </div>
              <div class="btnView">
                <el-button
                  class="btn-buy btn-audio"
                  type="primary"
                  @click="handleFastGet"
                  disabled
                >
                  快速收衣
                </el-button>
                <el-button
                  type="primary"
                  style="margin-left: 10px;"
                  @click="handleGet"
                >
                  {{ pageHeaderTitle }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="query-no" v-if="!isView">
          <div>
            <img src="../../assets/img/icon-coat-hanger.png" />
            <div>在页面右侧输入查询内容</div>
          </div>
        </div>
      </div>
      <div class="rightView">
        <div class="queryView">
          <div class="input-contentView">
            <div class="popView-headerView">用户查询</div>
            <div class="inputView" style="margin-top: 30px;">
              <input
                ref="phone"
                class="input"
                v-model="phone"
                placeholder="请输入用户卡号或手机号"
                @keyup.enter="_handleConfirmKey"
              />
            </div>
            <div class="contentView">
              <div class="keyboardView" @click.stop="_handleKeyPress">
                <div class="keyItem btn-audio key-1">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_1.png');
                    "
                    data-num="1"
                  ></div>
                </div>
                <div class="keyItem btn-audio key-2">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_2.png');
                    "
                    data-num="2"
                  ></div>
                </div>
                <div class="keyItem btn-audio key-3">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_3.png');
                    "
                    data-num="3"
                  ></div>
                </div>
                <div class="keyItem btn-audio key-4">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_4.png');
                    "
                    data-num="4"
                  ></div>
                </div>
                <div class="keyItem btn-audio key-5">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_5.png');
                    "
                    data-num="5"
                  ></div>
                </div>
                <div class="keyItem btn-audio key-6">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_6.png');
                    "
                    data-num="6"
                  ></div>
                </div>
                <div class="keyItem btn-audio key-7">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_7.png');
                    "
                    data-num="7"
                  ></div>
                </div>
                <div class="keyItem btn-audio key-8">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_8.png');
                    "
                    data-num="8"
                  ></div>
                </div>
                <div class="keyItem btn-audio key-9">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_9.png');
                    "
                    data-num="9"
                  ></div>
                </div>
                <div class="keyItem btn-audio key-Point">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_Point.png');
                    "
                    data-num="."
                  ></div>
                </div>
                <div class="keyItem btn-audio key-0">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_0.png');
                    "
                    data-num="0"
                  ></div>
                </div>
                <div class="keyItem btn-audio key-Del">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://static.bokao2o.com/wisdomDesk/images/PriceKey_Del.png');
                    "
                    data-num="D"
                  ></div>
                </div>
              </div>
              <div class="btnView">
                <button class="btn-clean btn-audio" @click="_handleClearKey">
                  <div class="valView">重输</div>
                </button>
                <button class="btn-comp btn-audio" @click="_handleConfirmKey">
                  确认
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--标签-->
    <el-dialog
      title="标签"
      :visible.sync="tagDialogVisible"
      center
      width="60%"
      append-to-body
      ref="query"
    >
      <div>
        <el-tag
          style="margin-top: 10px;"
          v-for="tag in tagList"
          :key="tag"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)"
        >
          {{ tag }}
        </el-tag>

        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        ></el-input>
        <el-button
          v-else
          class="button-new-tag"
          style="margin-top: 10px;"
          size="small"
          @click="showInput"
        >
          + 新增标签
        </el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="medium">
          取 消
        </el-button>
        <el-button type="primary" @click="postPutTag" size="medium">
          确 定
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogVisible"
      center
      width="60%"
      append-to-body
      ref="query"
    >
      <div slot="title" class="header-title">
        <div v-if="title == '查询团购信息'">
          <el-radio-group v-model="tabPosition">
            <el-radio-button label="left">美团团购</el-radio-button>
            <el-radio-button label="right">抖音团购</el-radio-button>
          </el-radio-group>
        </div>
        <div v-else>
          <span>{{ title }}</span>
        </div>
      </div>
      <div class="crad-header" v-if="tabPosition == 'left'">
        <el-row>
          <el-col>
            <el-tabs :tab-position="tabPosition" style="height: 70px;">
              <el-col style="margin:0">
                <el-input
                  v-model.trim="couponCode"
                  ref="query"
                  maxlength="50"
                  @keyup.enter.native="inquireQuery"
                  placeholder="请扫码/美团输入券码号"
                ></el-input>
                <el-button
                  type="primary"
                  :loading="tiktokLoading"
                  :disabled="this.couponCode == ''"
                  @click="inquireQuery"
                  >查询</el-button
                >
              </el-col>
            </el-tabs>
          </el-col>
          <el-col class="discount" v-if="this.userInfo.packageType === 1 && this.packageInfo != null">
            <div class="crad">
              <el-image class="imga" :src="packageInfo.packageImage"></el-image>
              <div class="crad-item">
                <div>
                  <span>{{ packageInfo.dealTitle }}</span>
                </div>
                <div>
                  <span
                    style="word-break: keep-all;color:red;padding-right:20px"
                    >￥{{ packageInfo.dealPrice }}</span
                  >
                </div>
              </div>
            </div>
            <div style="margin-top: 20px;color: red;">
              <span>*</span
              ><span style="margin-left: 5px"
                >请仔细阅读套餐名称,确认无误后进行收衣操作</span
              >
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="crad-header" v-else>
        <el-row>
          <el-col>
            <el-tabs :tab-position="tabPosition" style="height: 70px;">
              <el-col style="margin:0">
                <el-input
                  v-model.trim="tiktokRoll.volumeNumber"
                  ref="tiktok"
                  maxlength="50"
                  @keyup.enter.native="tiktokRoll.volumeNumber"
                  placeholder="请扫码/输入抖音券码号"
                >
                </el-input>
                <el-button
                  type="primary"
                  :loading="tiktokLoading"
                  :disabled="tiktokRoll.volumeNumber == ''"
                  @click="tiktokQuery"
                  >查询</el-button
                >
              </el-col>
            </el-tabs>
          </el-col>
          <el-col class="discount" v-if="this.userInfo.packageType === 3 && this.packageInfo != null">
            <div class="crad">
              <el-image class="imga" :src="packageInfo.packageImage"></el-image>
              <div class="crad-item">
                <div>
                  <span>{{ packageInfo.dealTitle }}</span>
                </div>
                <div>
                  <span
                    style="word-break: keep-all;color:red;padding-right:20px"
                    >￥{{ packageInfo.dealPrice }}</span
                  >
                </div>
              </div>
            </div>
            <div style="margin-top: 20px;color: red;">
              <span>*</span
              ><span style="margin-left: 5px"
                >请仔细阅读套餐名称,确认无误后进行收衣操作</span
              >
            </div>
          </el-col>
        </el-row>
      </div>

      <div v-if="title == '选择门店套餐规格'" class="storeDiv">
        <div
          v-for="(item, index) in storePackageDate"
          :key="index"
          :class="{ active: packageRadio == index }"
          class="storePackage"
          @click="storePackage(item, index)"
        >
          <el-radio-group v-model="packageRadio">
            <el-radio style="text-align:center" :label="index"></el-radio>
          </el-radio-group>
          <div class="crad-item">
            <el-image
              class="imgage"
              fit="contain"
              :src="item.packageImage"
            ></el-image>
            <div class="cardName">
              <span class="item-name">{{ item.packageName }}</span>
              <span style="color:red">￥{{ item.packagePrice }}</span>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="medium">
          取 消
        </el-button>
        <el-button type="primary" @click="handlePackage" size="medium">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { isMobile, isPhone } from "@/utils/validate";
import { getUserInfo, putTag } from "@/api/store/user";
import { checkPackage } from "@/api/package";
import {
  queryClothesDraftboxByPhone,
  inquireQuery,
  listPackageByStoreId,
  tikTok,
} from "@/api/clothes/clothes";
import parseTime from "@/utils/date";


export default {
  name: "getQuery",
  data() {
    return {
      parseTime,
      isView: false,
      getLoading: false,
      dialogVisible: false,
      inputVisible: false,
      phone: "",
      isActive: false,
      isActiveStore: "",
      heading: true,
      title: "",
      categoryIndex: "", //点击当前背景变成白色索引
      userInfo: {
        userId: "",
        phone: "",
        realName: "",
        memberUser: {},
        birthday: "",
        packageId: "",
        useService: "",
        userType: "",
        packageType: "",
        clothesNum: "",
        dealTitle: "",
        receiptCode: "",

        // userType: 0,
        address: "",
        addressLabel: "",
        lastMoneyRecord: {
          createTime: "",
          storeName: "",
          sourceName: "",
          date: "",
          money: "",
        },
      },
      dynamicTags: [],
      inputValue: "",
      tagList: [],
      // 美团套餐
      washWayActiveName: "1", // 1按件洗 2美团套餐 3门店自营
      pageHeaderTitle: "按件收",
      washWay: "1",
      // 券码
      couponCode: "",
      packageInfo: null,
      packageRadio: null,
      storePackageDate: [],
      userType: "",
      // 标签
      tagDialogVisible: false,
      tabPosition: "left",
      tiktokLoading:false,
      tiktokRoll: {
        //抖音团卷
        volumeNumber: "",
        phone: "",
      },
    };
  },
  mounted() {
    this.$nextTick(function() {
      this.$refs.phone.focus();
    });
  },
  watch: {
    tabPosition() {
      this.packageInfo = null
      if(this.$refs.query === undefined){
        this.$nextTick(() => {
          this.$refs.query.focus();
        });
      }
      if(this.$refs.tiktok === undefined){
        this.$nextTick(() => {
          this.$refs.tiktok.focus();
        });
      }
      this.couponCode = "";
      this.tiktokRoll = {
        //抖音团卷
        volumeNumber: "",
        phone: "",
      };
    },
  },
  methods: {
    // 洗涤方式标签页
    handleWashWayClick(tab, event) {
      this.pageHeaderTitle = tab.label;
      this.washWayActiveName = tab.name;
    },
    //团购页面查询
    inquireQuery() {
      if (this.couponCode == "") {
        this.$message.success("美团券码不能为空");
        return;
      }
      this.tiktokLoading = true
      inquireQuery(this.couponCode, this.userInfo.phone).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.packageInfo = res.data.data;
          console.log(this.packageInfo)
          this.userInfo.packageId = res.data.data.packageId;
          this.userInfo.useService = res.data.data.useService;
          this.userInfo.clothesNum = res.data.data.clothesNum;
          this.userInfo.dealTitle = res.data.data.dealTitle;
          this.userInfo.receiptCode = res.data.data.receiptCode;
          this.userInfo.packageType = res.data.data.packageType;
          this.couponCode = "";
          this.tiktokLoading = false
        } else {
          this.packageInfo = null;
          this.categoryIndex = "";
          this.$message.error(res.data.msg);
          this.tiktokLoading = false
        }
      });
    },
    //抖音查询
    tiktokQuery() {
      if (this.tiktokRoll.volumeNumber == "") {
        this.$message.success("抖音券码不能为空");
        return;
      }
      let params = {
        'couponCode':this.tiktokRoll.volumeNumber,
        'userPhone':this.userInfo.phone
      }
      this.tiktokLoading = true
      tikTok(params).then((res) => {
        if (res.data.code == 0) {
          console.log(res.data)
          this.packageInfo = res.data.data;
          this.userInfo.packageId = res.data.data.packageId;
          this.userInfo.useService = res.data.data.useService;
          this.userInfo.clothesNum = res.data.data.clothesNum;
          this.userInfo.dealTitle = res.data.data.dealTitle;
          this.userInfo.dealPrice = res.data.data.dealPrice?res.data.data.dealPrice:null;
          this.userInfo.deductionAmount = res.data.data.deductionAmount?res.data.data.deductionAmount:null;
          this.userInfo.grouponType = res.data.data.grouponType?res.data.data.grouponType:null;
          this.userInfo.receiptCode = res.data.data.receiptCode
          this.userInfo.packageType = res.data.data.packageType;
          this.tiktokRoll.volumeNumber = "";
          this.tiktokLoading = false
        } else {
          this.packageInfo = null;
          this.categoryIndex = "";
          this.$message.error(res.data.msg);
          this.tiktokLoading = false
        }
      });
    },
    changeClick() {
      if (this.tiktokRoll.tiktokOpValue === "1") {
        this.$nextTick(() => {
          this.$refs.tiktok.focus();
        });
      }
    },
    back() {
      this.isView = false;
      this.heading = true;
    },
    //处理按键
    _handleKeyPress(e) {
      this.$refs.phone.focus();
      console.log(e)
      let num = e.target.dataset.num;
      //不同按键处理逻辑
      // -1 代表无效按键，直接返回
      if (num == undefined) return false;
      switch (String(num)) {
        //小数点
        case ".":
          this._handleDecimalPoint();
          break;
        //删除键
        case "D":
          this._handleDeleteKey();
          break;
        //清空键
        case "C":
          this._handleClearKey();
          break;
        //确认键
        case "S":
          this._handleConfirmKey();
          break;
        default:
          this._handleNumberKey(num);
          break;
      }
    },
    //处理小数点函数
    _handleDecimalPoint() {
      //如果包含小数点，直接返回
      if (this.phone.indexOf(".") > -1) return false;
      //如果小数点是第一位，补0
      if (!this.phone.length) this.phone = "0.";
      //如果不是，添加一个小数点
      else this.phone = this.phone + ".";
    },
    //处理删除键
    _handleDeleteKey() {
      let S = this.phone;
      //如果没有输入，直接返回
      if (!S.length) return false;
      //否则删除最后一个
      this.phone = S.substring(0, S.length - 1);
    },
    //处理清空键
    _handleClearKey() {
      this.phone = "";
    },
    //处理数字
    _handleNumberKey(num) {
      let S = this.phone;
      //如果有小数点且小数点位数不小于2
      if (S.indexOf(".") > -1 && S.substring(S.indexOf(".") + 1).length < 2)
        this.phone = S + num;
      //没有小数点
      if (!(S.indexOf(".") > -1)) {
        //如果第一位是0，只能输入小数点
        if (num == 0 && S.length == 0) this.phone = "0.";
        else {
          if (S.length && Number(S.charAt(0)) === 0) return;
          this.phone = S + num;
        }
      }
    },
    //提交
    _handleConfirmKey() {
      let S = this.phone;
      this.getLoading = true;
      //未输入
      if (this.phone.trim() === "") {
        this.$message.error("请输入手机号/卡号");
        this.getLoading = false;
        return false;
      } else {
        const params = new URLSearchParams();
        params.append("phoneOrCardNum", this.phone);
        getUserInfo(params).then((response) => {
          if (response.data.code == 0) {
            this.heading = false;
            this.userInfo = response.data.data;
            this.dynamicTags = this.tagList =
              response.data.data.userLabelList != null
                ? response.data.data.userLabelList
                : [];
            this.isView = true;
          }
          this.getLoading = false;
        });
      }
    },
    //查询门店自营套餐
    listPackageByStoreId() {
      listPackageByStoreId().then((res) => {
        this.storePackageDate = res.data.data;
      });
    },
    //点击选择门店套餐
    storePackage(item, index) {
      console.log(index, "门店套餐");
      this.packageRadio = index;
      this.userInfo.packageType = item.packageType;
      this.userInfo.packageId = item.packageId;
      this.userInfo.useService = item.useService;
      this.userType = item.userType;
      this.userInfo.clothesNum = item.clothesNum;
      this.userInfo.dealTitle = item.packageName;
      this.isActiveStore = index;
    },
    handleGet() {
      if (!isMobile(this.phone) || !isPhone(this.phone)) {
        if (!this.userInfo.userId) {
          this.$message.error("请输入正确的手机号/电话号码");
          return;
        }
      }
      if (this.userInfo.realName === null) {
        this.$message.error("请输入用户姓名");
        return;
      }

      // 选择美团套餐
      if (this.washWayActiveName == "2") {
        this.title = "查询团购信息";
        this.userInfo.packageId = "";
        this.userInfo.useService = "";
        this.userInfo.clothesNum = "";
        this.userInfo.dealTitle = "";
        this.couponCode = "";
        this.categoryIndex = "";
        this.packageInfo = null;
        this.isActive = false;
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.query.focus();
        });
        return;
      }
      // 选中门店套餐
      if (this.washWayActiveName == "3") {
        this.title = "选择门店套餐规格";
        this.dialogVisible = true;
        this.listPackageByStoreId();
        return;
      }
      queryClothesDraftboxByPhone(this.userInfo.phone).then((response) => {
        console.log(response, "aaa111");
        if (response.data.code === 0) {
          if (response.data.data) {
            // 满足提示
            this.$confirm(
              "该用户在草稿箱里有衣物是否进入草稿箱继续收衣, 是否继续?",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.$router.push({ path: "/home/more/draftbox" });
              })
              .catch(() => {
                let data = {
                  userId: this.userInfo.userId,
                  phone: this.userInfo.phone,
                  name: this.userInfo.realName,
                  address: this.userInfo.address,
                  userType: this.userInfo.userType,
                  cardName: this.userInfo.cardName,
                };
                let userInfo = JSON.stringify(data);
                this.$router.push({
                  path: "/get/types",
                  query: { data: userInfo },
                });
                this.getLoading = false;
              });
          } else {
            let data = {
              userId: this.userInfo.userId,
              phone: this.userInfo.phone,
              name: this.userInfo.realName,
              address: this.userInfo.address,
              userType: this.userInfo.userType,
              cardName: this.userInfo.cardName,
              balance:
                this.userInfo.memberUser !== null
                  ? this.userInfo.memberUser.balance
                  : 0,
            };
            let userInfo = JSON.stringify(data);
            this.$router.push({
              path: "/get/types",
              query: { data: userInfo },
            });
            this.getLoading = false;
          }
        }
      });
    },
    handleFastGet() {
      this.$message.error("功能待开发");
    },
    // 标签更多
    more() {
      this.tagDialogVisible = true;
    },
    handleClose(tag) {
      this.tagList.splice(this.tagList.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.tagList.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    // 处理标签
    async postPutTag() {
      try {
        const res = await putTag({
          userId: this.userInfo.userId,
          userLabel: this.tagList,
        });
        this.$message.success(res.data.msg);
        this.tagDialogVisible = false;
      } catch (error) {
        console.log(error);
      }
    },
    // 处理团购套餐
    async handlePackage() {
      if (!isMobile(this.phone) || !isPhone(this.phone)) {
        if (!this.userInfo.userId) {
          this.$message.error("请输入正确的手机号/电话号码");
          return;
        }
      }
      if (this.userInfo.realName === null) {
        this.$message.error("请输入用户姓名");
        return;
      }

      if (this.washWayActiveName == "2") {
        if (this.packageInfo == null) {
          if (this.tabPosition == "left") {
            this.$message.error("请先查询美团套餐内容");
            return;
          } else {
            this.$message.error("请先查询抖音套餐内容");
            return;
          }
        }
      } else if (this.washWayActiveName == "3") {
        if (this.packageRadio == null) {
          this.$message.error("请选择门店套餐");
          return;
        }
        // 检验
        let res = await checkPackage(this.userInfo.phone, this.userType);
        if (res.data.code === 1) {
          this.$message.error(res.data.msg);
          return;
        }
      }

      let data = {
        userId: this.userInfo.userId,
        phone: this.userInfo.phone,
        name: this.userInfo.realName,
        address: this.userInfo.address,
        userType: this.userInfo.userType,
        cardName: this.userInfo.cardName,
        packageId: this.userInfo.packageId,
        useService: this.userInfo.useService,
        packageType: this.userInfo.packageType,
        clothesNum: this.userInfo.clothesNum,
        dealTitle: this.userInfo.dealTitle,
        dealPrice:this.userInfo.dealPrice?this.userInfo.dealPrice:null,
        deductionAmount:this.userInfo.deductionAmount?this.userInfo.deductionAmount:null,
        grouponType:this.userInfo.grouponType?this.userInfo.grouponType:null,
        receiptCode: this.userInfo.receiptCode,

        balance:
          this.userInfo.memberUser !== null
            ? this.userInfo.memberUser.balance
            : 0,
      };
      console.log(data)
      let userInfo = JSON.stringify(data);
      this.$router.push({ path: "/get/types", query: { data: userInfo } });
      this.getLoading = false;
    },
    //出来抖音团购
    async tiktokButton() {
      if (!isMobile(this.phone) || !isPhone(this.phone)) {
        if (!this.userInfo.userId) {
          this.$message.error("请输入正确的手机号/电话号码");
          return;
        }
      }
      if (this.userInfo.realName === null) {
        this.$message.error("请输入用户姓名");
        return;
      }
      if (this.washWayActiveName == "2") {
        if (this.packageInfo == null) {
          this.$message.error("请先查询抖音套餐内容");
          return;
        }
      }
    },
    cancel() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.prentView {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: #fff;
}

.tabs {
  display: flex;
  justify-content: center;

  /deep/ .el-tabs__item {
    font-size: 16px;
  }

  /deep/ .el-tabs__item.is-active {
    color: #3370FF;
    font-weight: bold;
    font-size: 20px !important;
  }
}

/deep/ .el-tabs__nav-wrap::after {
  content: none;
}

.crad-header {
  position: relative;
  text-align: center;

  .discount {
    margin: 20px 0 0 0;
  }

  .crad {
    position: relative;
    padding: 10px 0;
    overflow: auto;
    width: 300px;
    margin: 0 auto;
    background: linear-gradient(to bottom, #fff, #eee);
    border-radius: 5px;

    .hook {
      position: absolute;
      left: 43%;
      top: 30%;
    }

    .crad-item {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-top: 10px;
      font-weight: bold;
    }
  }

  .imga {
    height: 130px;
    border-radius: 5px;
  }

  .active {
    margin: 0 auto;
    width: 300px;
    height: 150px;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.3);
    border: 1.5px solid #78bce0;
  }
}

.storeDiv {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;

  .storePackage {
    text-align: center;
    width: 248px;
    height: 160px;
    border: 1px solid #dcdfe6;
    border-radius: 8px;
    margin: 5px;

    .imgage {
      height: 100px;
      padding: 5px;
    }
    .cardName {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #000;
      padding: 0 10px;
      .item-name {
        max-width: 170px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .active {
    border: 1px solid #3370FF;
  }
}

/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #fff;
}

/deep/ .el-radio__label {
  color: #fff;
}

/deep/ .el-radio__inner {
  margin-left: 20px;
}

.el-input {
  width: 30%;
  margin-right: 20px;
}

.stars {
  display: inline-block;
  color: #ff4600;
  margin-right: 3px;
  margin-top: 3px;
  font-size: 14px;
}

.orderQueryView {
  position: relative;
  background: #fff;
  font-family: PingFangSC-Regular;

  .topView {
    display: flex;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    padding: 20px 25px 10px 25px;
    z-index: 100;

    .backView {
      height: 30px;
      flex: 1;

      span {
        cursor: pointer;

        i:nth-child(1) {
          font-size: 22px;
          vertical-align: top;
        }

        i {
          font-style: normal;
        }
      }

      .btn-back {
        font-size: 14px;
        color: #666;
        height: 30px;
        padding: 0;
        border: none;
        text-align: left;
      }
    }

    .aliKoubeiView {
      display: flex;
      height: 30px;
      line-height: 30px;

      .btn-kbAuth {
        width: 82px;
        font-size: 16px;
        color: #e7480a;
      }

      button {
        padding: 0;
      }
    }
  }

  .contentView {
    position: relative;
    height: 100%;
    padding-top: 60px;

    .leftView {
      height: 100%;

      /*.menuView {*/
      /*    position: relative;*/
      /*    display: flex;*/
      /*    padding-left: 30px;*/
      /*    height: 44px;*/
      /*    overflow: hidden;*/
      /*}*/

      /*.boxView {*/
      /*    padding: 15px 22px 20px 22px;*/
      /*    overflow: auto;*/
      /*}*/
      .sellInfoView {
        display: flex;
        width: 100%;
        height: 100%;
        padding-top: 25px;
        padding-bottom: 10px;
        overflow-x: hidden;
        overflow-y: auto;

        .inputView {
          display: flex;
          justify-content: space-between;
          height: 44px;
          padding: 8px 10px;
          line-height: 28px;
          background: #f8f8f8;
          border-radius: 6px;
          font-size: 15px;
          margin-bottom: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .inputView-date {
            margin-left: 20px;
          }

          .el-tag {
            margin-right: 5px;
          }

          p {
            margin: 0;
            text-align: right;
            cursor: pointer;
          }

          //    }
          input {
            flex: 1;
            background: #f8f8f8;
            text-align: right;
            font-size: 15px;
            color: #333;
            line-height: 28px;
            border: none;
            outline: none;
            padding: 0;
          }
        }

        .cusView {
          width: 380px;
          min-width: 380px;
          padding: 0 55px 35px 55px;
          border-right: 0.5px solid rgba(220, 220, 220, 0.7);
          overflow-x: hidden;
          overflow-y: auto;

          .tView {
            font-family: PingFangSC-Semibold;
            font-size: 20px;
            color: #333;
            line-height: 30px;
            margin-left: -25px;
            margin-bottom: 25px;
          }

          .cardView {
            position: relative;
            width: 270px;
            height: 170px;
            margin-bottom: 15px;

            img {
              width: 270px;
              height: 170px;
              border-radius: 6px;
            }

            .cardNameView {
              position: absolute;
              left: 15px;
              top: 15px;
              line-height: 25px;
              font-family: PingFangSC-Semibold;
              font-size: 18px;
              color: #fff;
            }
          }

          .infoView {
            /*border-bottom: 0.5px solid rgba(220, 220, 220, 0.7);*/

            div {
              display: flex;
              align-items: center;

              // margin-bottom: 12px;
              // height: 44px;
              // padding: 8px 10px;
              // line-height: 28px;
              background: #f8f8f8;
              border-radius: 6px;
              font-size: 15px;

              label {
                // width: 90px;
                color: #666;
              }
            }

            .inputView-label {
              text-align: right;
              //    margin-bottom: 5px;
              margin-left: 10px;
              //    width: 70px;
            }
          }
        }

        .accView {
          flex: 1;
          padding: 0 0 15px 0;
          overflow-x: hidden;
          overflow-y: auto;

          .tView {
            font-family: PingFangSC-Semibold;
            font-size: 20px;
            color: #333;
            line-height: 30px;
            margin-left: -25px;
            margin-bottom: 25px;
            padding: 0 55px;
          }

          .buyInfoView {
            padding: 0 40px;
            padding-bottom: 35px;

            .amtView {
              margin-bottom: 60px;
            }
          }
        }

        .btnView {
          text-align: center;
          margin-top: 12px;
          display: flex;

          button {
            width: 340px;
            height: 44px;
            // background: #3370ff;
            border-radius: 6px;
            line-height: 44px;
            padding: 0;
            font-size: 16px;
            color: #fff;
          }
        }
      }

      .query-no {
        display: flex;
        height: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;

        > div > img {
          width: 120px;
        }
      }
    }

    .rightView {
      position: absolute;
      top: 45%;
      right: 25px;
      transform: translateY(-50%);
      // padding: 20px 20px 0 0;

      .queryView {
        width: 100%;
        height: 100%;
        padding-top: 25px;

        .tView {
          font-family: PingFangSC-Semibold;
          font-size: 20px;
          color: #333;
          line-height: 30px;
          margin-left: -25px;
          margin-bottom: 25px;
          padding: 0 55px;
        }

        .input-contentView {
          /*width: 520px;*/
          /*max-height: 80%;*/
          border-radius: 6px;
          background: rgb(255, 255, 255);
          /*overflow: auto;*/
          box-shadow: #3333 0px 0px 20px 0px;

          .popView-headerView {
            line-height: 40px;
            height: 60px;
            font-family: PingFangSC-Semibold;
           font-size: 24px;
            color: #333333;
            text-align: center;
            padding: 20px 0 5px 0;
          }

          .inputView {
            display: flex;
            position: relative;
            left: 0;
            right: 0;
            width: 386px;
            height: 60px;
            line-height: 60px;
            border-radius: 6px;
            padding: 0 20px;
            background: #fff;
            margin: auto;
            cursor: pointer;
            text-align: center;
            border: 1px solid rgba(220, 222, 224, 100);
            .input{
              background-color: #fff;
              

            }
            > .label-tip {
              flex: 1;
              display: block;
              font-size: 16px;
              color: #666;
              text-align: center;
            }

            /*> .label-val {*/
            /*    flex: 1;*/
            /*    display: block;*/
            /*    font-size: 24px;*/
            /*    font-family: DINAlternate-Bold;*/
            /*    color: #333;*/
            /*    text-align: center;*/
            /*}*/

            input {
              box-sizing: border-box;
              display: block;
              width: 100%;
              height: 100%;
              padding: 0 0.75rem 0 0.25rem;
              font-size: 20px;
              color: #333442;
              border: 0 none;
              outline: none;
              font-family: DINAlternate-Bold;
              background: #f8f8f8;
            }
          }

          .contentView {
            position: relative;
            margin: auto;
            display: flex;
            width: 424px;

            .keyboardView {
              flex: 1;
              overflow: hidden;
              text-align: center;
              padding: 20px 7px 20px 20px;

              .keyItem {
                position: relative;
                float: left;
                cursor: pointer;
                width: 92px;
                height: 58px;
                overflow: hidden;
                border-radius: 6px;
                // box-shadow: 0 0 3px rgba(220, 220, 220, 0.7);
               background-color: rgba(242, 243, 245, 100);
                margin-right: 12px;
                margin-bottom: 12px;
                .valView {
                  position: absolute;
                  z-index: 11;
                  left: 0;
                  top: 0;
                  cursor: pointer;
                  width: 92px;
                  height: 58px;
                  background-position: center;
                  background-size: 16px 38px;
                  background-repeat: no-repeat;
                }
              }

              .keyItem:nth-child(3n + 3) {
                margin-right: 0;
              }

              .keyItem.key-Del > .valView {
                background-size: 28px;
              }
            }

            .btnView {
              width: 97px;
              padding: 20px 20px 0 5px;

              button {
                position: relative;
                width: 72px;
                height: 128px;
                overflow: hidden;
                border-radius: 6px;
                box-shadow: 0 0 3px rgba(220, 220, 220, 0.7);
                font-size: 18px;
                padding: 0;
                border: none;
                outline: none;
                box-sizing: border-box;
                cursor: pointer;
              }

              button.btn-clean {
                background: transparent;
                color: #000;
                margin-bottom: 12px;
                font-weight: 700;
                  background-color: rgba(242, 243, 245, 100);
              }

              button.btn-comp {
                // background: #3370ff;
                background: transparent;
                color: #fff;
                font-weight: 700;
                  background-color: #3370FF;
              }
            }
          }
        }
      }
    }
  }
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

::v-deep .el-input--suffix .el-input__inner {
  padding-right: 30px;
  width: 110px;
  background: #3370FF;
  color: #fff;
}
// ::v-deep .crad-header ::-webkit-input-placeholder {
//   color: white;
// }
// ::v-deep .el-icon-arrow-up:before {
//   color: #fff;
// }
::v-deep .el-tabs--left .el-tabs__header.is-left {
  margin: 0px;
}
::v-deep .el-tabs--right .el-tabs__nav-wrap.is-right {
  margin: 0px;
}

::v-deep .el-tabs--right .el-tabs__header.is-right {
  margin: 0px;
}
</style>
